

export default {

    init() {
        $(document.body).on('click','button.hamburger',function(e){
            e.preventDefault()
            if($(this).hasClass('is-active')){
                $(this).removeClass('is-active')
                $('.menu-mobile').removeClass('active');
            } else {
                $(this).addClass('is-active')
                $('.menu-mobile').addClass('active');
            }
        })
        $(document.body).keyup(function(e) {
          if (e.keyCode === 27 && $('.hamburger').hasClass('is-active')){
                $('.hamburger').removeClass('is-active')
                $('.menu-mobile').removeClass('active');
          }

        });

    },
    finalize() {

    },
};
