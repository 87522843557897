/**
 * External Dependencies
 */

import $ from 'jquery'

import Router from './router'

import common from './routes/common'

/** Populate Router instance with DOM routes */
const routes = new Router({
  common,
});


$(document).ready(() => {
    routes.load();
})
